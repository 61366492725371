import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '@/actions/user';
import { useCallback } from 'react';
import { useRef } from 'react';
import {
  resetSchool,
  setDemoGroups,
  setGroups,
  setSchools,
  switchSchool
} from '@/actions/school';
import { toaster } from '@/components/common';
import { useNavigate } from 'react-router';
import { Api } from '@/api';
import analytics from '@/analytics';
import { setGroup } from '@/actions/group';
import { jwtDecode } from 'jwt-decode';
import { logout } from '@/actions/auth';
import { isArray } from 'lodash';

const useLoadUserContext = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userRef = useRef({ context: null });
  const groupId = useSelector(s => s.group.groupId);

  const getSchools = context => {
    let usersSchools = [];
    try {
      const usersTeachers = context.teachers || [];
      if (usersTeachers?.length) {
        usersSchools = usersTeachers.map(t => ({
          ...t.school,
          school: t.school,
          teacher: t
        }));
        analytics.intercom.set({
          schools_count: usersSchools?.length || 0
        });
      }
    } catch (e) {
      console.error(e);
    }
    return usersSchools;
  };

  const getCurrentSchool = (schoolId = null, schools) => {
    const sortedSchools = schools.sort((a, b) => b.schoolId - a.schoolId);
    const currentSchool =
      sortedSchools.find(s => s.schoolId === schoolId) ?? sortedSchools[0];
    return currentSchool;
  };

  const getGroups = useCallback(context => {
    const groups = context.groups || [];
    const demoGroups = context.demoGroups || [];

    // Filter out demoGroups from groups
    const filteredGroups = groups.filter(
      g => !demoGroups.find(dg => dg.groupId === g.groupId)
    );

    // Get the largest year group odinal for each group
    const flattenedGroups = filteredGroups.map(r => {
      let yearGroup = {
        yearGroupId: null,
        yearGroupName: null
      };
      try {
        if (r.yearGroups?.length > 0) {
          const filteredYearGroups =
            r.yearGroups
              .map(yg => {
                const digits = yg.name.match(/\d+/g) ?? [];
                let ordinal = digits.length > 0 ? parseInt(digits[0]) : -1;
                return { ...yg, ordinal };
              })
              .sort((a, b) => b.ordinal - a.ordinal) ?? [];
          yearGroup = {
            yearGroupId: filteredYearGroups[0]?.countryYearGroupId,
            yearGroupName: filteredYearGroups[0]?.name,
            ordinal: filteredYearGroups[0]?.ordinal
          };
        }
      } catch (e) {
        console.error(e);
      }

      return {
        ...r,
        ...yearGroup
      };
    });

    // Sort by year group asc
    return flattenedGroups.sort((a, b) => a.ordinal - b.ordinal);
  }, []);

  const setTeacherContext = useCallback(
    async schoolId => {
      const context = userRef.current.context;
      if (!context || context?.userRole !== 'Teacher') return;

      try {
        const schools = getSchools(context);
        const currentSchool = getCurrentSchool(schoolId, schools);

        if (currentSchool) {
          const { schoolId, teacher } = currentSchool;
          dispatch(setSchools(schools));
          dispatch(switchSchool(schoolId));

          if (
            teacher?.teacherId &&
            teacher?.schoolMembershipStatus !== 'Pending'
          ) {
            const groups = getGroups(context);

            dispatch(setDemoGroups([]));
            dispatch(setGroups(groups));
            if (groups.length) {
              const selectedGroup = groups.find(g => g.groupId === groupId);

              // If there is only one group, select it
              const firstGroup = groups.length === 1 ? groups[0] : null;

              dispatch(
                setGroup(
                  selectedGroup ?? firstGroup
                )
              );
            }
          }
        } else {
          dispatch(resetSchool());
        }
      } catch (e) {
        console.error(e);
        analytics.trackError(e);
      }
    },
    [dispatch, getGroups, groupId]
  );

  const getTokenUserRole = useCallback(token => {
    let tokenUserRole = 'Teacher';

    try {
      // Fetch token
      if (token?.length > 0) {
        localStorage.setItem('authToken', token);
      } else {
        token = localStorage.getItem('authToken');
      }

      if (token) {
        // Decode token and get the user role claim
        const decodedToken = jwtDecode(token);

        console.warn('decodedToken', decodedToken, token);

        if (decodedToken) {
          const roles =
            decodedToken[
              'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
            ];
          if (isArray(roles)) {
            // sort roles so Teacher is always first IF present
            tokenUserRole = roles.sort((a, b) => {
              if (a === 'Teacher') return -1;
              if (b === 'Teacher') return 1;
              return 0;
            })[0];
          } else if (typeof roles === 'string') {
            tokenUserRole = roles;
          }
        }
      }
    } catch (e) {
      console.error(e);
    }
    return { tokenUserRole, token };
  }, []);

  const redirectNonTeacher = useCallback(
    async inputToken => {
      const { tokenUserRole, token } = getTokenUserRole(inputToken);

      if (tokenUserRole === 'Teacher' || !token) return;

      try {
        const redirectUri = await Api.tokenRedirect(token);
        dispatch(logout());
        localStorage.removeItem('authToken');
        sessionStorage.clear();
        window.open(redirectUri, '_self');
      } catch (e) {
        console.error(e);
      }
    },
    [dispatch, getTokenUserRole]
  );

  const setUserContext = useCallback(
    async (schoolId = null, token = null) => {
      try {
        await redirectNonTeacher(token);
        const user = await Api.getUserRoleContext(schoolId);
        if (user) {
          userRef.current.context = user;

          dispatch(setUser(user));
          await setTeacherContext(schoolId);

          const from = localStorage.getItem('from');

          if (from) {
            localStorage.removeItem('from');
            navigate(from);
          }
        }
      } catch (e) {
        console.error(e);
        analytics.trackError(e);
      }
      return userRef.current.context ?? {};
    },
    [dispatch, navigate, redirectNonTeacher, setTeacherContext]
  );

  return {
    context: userRef.current.context,
    loadUserContext: async (schoolId, token) =>
      await setUserContext(schoolId, token)
  };
};

export default useLoadUserContext;
