import FullPageError from './FullPageError';
import { useState, useEffect } from 'react';
import * as Sentry from '@sentry/react';

const ErrorBoundary = ({ children }) => {
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState(null);
  const [info, setInfo] = useState(null);
  const [sentryEventId, setSentryEventId] = useState(null);

  useEffect(() => {
    const handleError = (error, info) => {
      const eventId = Sentry.captureException(error);
      setSentryEventId(eventId);
      setHasError(true);
      setError(error);
      setInfo(info);
    };

    const errorListener = event => {
      handleError(event.error, event.errorInfo);
    };

    window.addEventListener('error', errorListener);

    return () => {
      window.removeEventListener('error', errorListener);
    };
  }, []);

  if (hasError) {
    return (
      <FullPageError
        title="Something went wrong"
        description="Please refresh your browser and try again"
        isLogoutOption={true}
        error={error}
        info={info}
        sentryEventId={sentryEventId}
      />
    );
  }

  return children;
};

export default ErrorBoundary;
