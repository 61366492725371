import styled from 'styled-components';
import { Button, Card } from '@/components/common';
import Navigation from '@/components/Navigation';
import { useNavigate } from 'react-router';
import { useState, useEffect } from 'react';
import * as Sentry from '@sentry/react';

const FullPageError = ({
  title = 'Something went wrong',
  description = 'Please refresh your browser and try again',
  isLogoutOption = false,
  error = null,
  signup = false,
  info = null,
  sentryEventId = null
}) => {
  const navigate = useNavigate();
  const [showErrorDetails, setShowErrorDetails] = useState(false);

  const handleToggleErrorDetails = () => {
    setShowErrorDetails(!showErrorDetails);
  };

  const truncateString = (str, maxLength) => {
    if (!str || !maxLength) return null;
    if (str.length <= maxLength) {
      return str;
    }
    return str.slice(0, maxLength) + '...';
  };

  const getUserAgent = () => {
    return navigator.userAgent;
  };

  const getPageURL = () => {
    return window.location.href;
  };

  return (
    <Container>
      <Navigation />
      <Content>
        <Card>
          <Card.Body>
            <h2>{title}</h2>
            <h4 className="subtitle mb-3">{description}</h4>
            {isLogoutOption && (
              <p className="mb-4">
                If this issue persists try{' '}
                <LogoutLink onClick={() => navigate('/logout')}>
                  logging out
                </LogoutLink>{' '}
                and back in again
              </p>
            )}
            {signup && (
              <Signup>
                <div>Don't have an account?</div>
                <div>
                  <Button to="/signup" color="primary" outline>
                    Sign up
                  </Button>
                </div>
              </Signup>
            )}
            <Button
              onClick={handleToggleErrorDetails}
              color="secondary"
              size="small">
              {showErrorDetails ? 'Hide Error Details' : 'Show Error Details'}
            </Button>
            {showErrorDetails && error?.toString() && (
              <ErrorDisplay>
                <p>{error?.toString()}</p>
                <ErrorDetails>
                  {truncateString(info?.componentStack, 255)}
                  <p>
                    <strong>Browser:</strong> {getUserAgent()}
                  </p>
                  <p>
                    <strong>Page URL:</strong> {getPageURL()}
                  </p>
                  {sentryEventId && (
                    <p className="mb-0">
                      <strong>ID:</strong> {sentryEventId}
                    </p>
                  )}
                </ErrorDetails>
              </ErrorDisplay>
            )}
          </Card.Body>
        </Card>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  z-index: 300;
  background-color: var(--tint-color);
`;

const Content = styled.div`
  margin: 0 auto;
  padding: 1.5rem;
  max-width: 30rem;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Signup = styled.div`
  display: flex;
  margin-top: 1rem;
  border-top: 1px solid var(--gray-lighter);
  padding-top: 1rem;
  align-items: center;
  justify-content: space-between;
`;

const LogoutLink = styled.span`
  cursor: pointer;
  text-decoration: underline;
  color: var(--indigo-dark);
`;

const ErrorDisplay = styled.div`
  padding: 1rem;
  color: var(--red);
  margin-top: 0.75rem;
  border-radius: 0.5rem;
  background: var(--dark);
  color: var(--pink-lightest);
  font-weight: 600;
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono',
    'Courier New', monospace;
  box-shadow: inset 0.125rem 0.125rem 0.25rem rgba(0, 0, 0, 0.25);
  border-left: 0.5rem solid var(--red);
`;

const ErrorDetails = styled.div`
  font-size: 12px;
  font-weight: 500;
`;

export default FullPageError;
